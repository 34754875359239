@import "variables";
@import "Assets/Templates/NewDesign/Scss/base";
@import "icons";

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('https://p.ec-cloud.org/promo/fonts/alegreya-sans-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('https://p.ec-cloud.org/promo/fonts/alegreya-sans-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@import "mixins.scss";