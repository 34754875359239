html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

@import "Components/Angular/main";

@import "Components/Form/main";

@import "Components/Stepper/main";

@import "mixins";

