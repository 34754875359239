#subscription-form {
  &.show {
    position: fixed;
    display: block;
    opacity: 1;
    left: 0;
    height: 100%;
    width: 100%;
    top: 4rem;
    overflow-y: scroll;
    padding-bottom: 72px;
    background: white;
    z-index: 20;
  }
}