form-container {
  & payment-field {
    & mat-tab-group {
      & mat-tab-header {
        & .mat-tab-label-container {
          overflow-x: auto;
        }
      }
    }
  }
}

radio-button-field {
  display: flex;

  & mat-radio-button {
    & label {
      &.mat-radio-label {
        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }
  }
}

call-me-back-button {
  position: fixed;
  bottom: 10%;
  right: 1%;
  z-index: 20;

  &.userdata, &.payment {
    bottom: 0;
    top: 1.5%; 
  }

  & button {
    background-color: #d92222 !important;
  }
}

app-submit-button, submit-button {
  margin: 2rem 0;
  & button{
    border-radius: 1.5rem!important;
  }
}

app-ingenico-field {
  & button {
    padding: 0 !important;
    margin: 0 !important;
  }
}