body {
  &.confirmOrder, &.createLogin {
    footer {
      position: relative
    }
  }
}

@media (min-width: 1024px) {
  .grecaptcha-badge {
    visibility: visible;
  }
  body {
    &.confirmorder, &.createlogin, &.thanks {
      footer {
        position: fixed;
        width: 100%;
        bottom: 0;
      }
    }
  }
}
