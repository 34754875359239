﻿@import 'variables';

.wrapper {
  width: 100%;
  padding: 1.5rem 0 3rem 0;
}

.stepper {
  display: table;
  width: 100%;
  position: relative;

  .stepper-item, .stepper-connector {
    display: table-cell;
  }

  .stepper-item {
    width: $circleSize +60px;
    text-align: center;

    &.step-complete {
      .stepper-circle {
        background-color: $main-color;
      }
    }

    &.step-last-complete {
      .stepper-label {
        width: 100%;
        display: inline-block;
      }
    }

    .stepper-label {
      width: 100%;
      display: none;
      position: absolute;
      top: $circleSize + 5px;
      left: 0;
      text-align: center;
    }
  }

  .stepper-connector {
    hr {
      height: 2px;
      margin: 0 -#{$connectorPullIn};
      transform: translateY(-0.3em);
      color: #ddd;
      background-color: #ddd;
      border: none;
    }
  }

  @media(min-width: 768px) {
    .stepper-item {
      position: relative;
      width: $circleSize +111px;

      .stepper-label {
        display: inline-block;
      }
    }

  }
}

.stepper-circle {
  width: $circleSize;
  height: $circleSize;
  display: inline-block;
  font-weight: bold;
  font-style: normal;
  color: #fff;
  line-height: 24px;
  text-align: center;
  background-color: #999;
  border-radius: 50%;
}
