﻿@font-face {
  font-family: 'icomoon';
  src:  url('#{$font-path}icomoon.eot?wbsnee');
  src:  url('#{$font-path}icomoon.eot?wbsnee#iefix') format('embedded-opentype'),
  url('#{$font-path}icomoon.ttf?wbsnee') format('truetype'),
  url('#{$font-path}icomoon.woff?wbsnee') format('woff'),
  url('#{$font-path}icomoon.svg?wbsnee#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hand-coin:before {
  content: "\e90c";
}
.icon-wallet:before {
  content: "\e913";
}
.icon-bitcoin:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e90b";
}
.icon-monitoring:before {
  content: "\e90e";
}
.icon-trending-up:before {
  content: "\e90f";
}
.icon-support:before {
  content: "\e910";
}
.icon-feed:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e902";
}
.icon-local_phone:before {
  content: "\e902";
}
.icon-card-giftcard:before {
  content: "\e90d";
}
.icon-menu:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e903";
}
.icon-add:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e901";
}
.icon-book:before {
  content: "\e907";
}
.icon-leaf:before {
  content: "\e908";
}
.icon-double-arrow-down:before {
  content: "\e909";
}
.icon-double-arrow-up:before {
  content: "\e90a";
}